<template lang="pug">
.account-settings-auth
  .account-settings-auth__authContent
    .account-settings-auth__authContent__header
      FcwClientLogo
    .account-settings-auth__loginContainer
      NuxtPage
    .account-settings-auth__bottomContent
      .account-settings-auth__bottomContent__links
        FcwRouterLink(
          :location="{ name: 'terms-and-conditions' }"
          target="_blank"
          hide-arrow
        )
          span {{ t('common__terms_and_conditions') }}
        FcwRouterLink(
          :location="{ name: 'legal-notice' }"
          target="_blank"
          hide-arrow
        )
          span {{ t('common__legal_notice') }}
      .account-settings-auth__bottomContent__locale
        FcwLocaleSelect(display-mode="name")
</template>

<style lang="stylus">
.account-settings-auth
  background-color var(--color--neutral--dark-4)
  height 100vh
  width 100%
  display flex

.account-settings-auth__authContent
  position absolute
  background-color var(--color--neutral--light-5)
  border-radius rem(48)
  padding rem(24) rem(96)
  display flex
  left 50%
  top 50%
  transform translateX(-50%) translateY(-50%)
  flex-direction column
  justify-content center
  align-items center
  min-height rem(560)
  width 'min(66%, %s)' % rem(640)

  +media-down('sm')
    width 100%
    height 100%
    border-radius 0
    padding rem(24) rem(40)

.account-settings-auth__authContent__header
  position absolute
  top 0
  left 0
  padding rem(16) rem(40)
  align-self flex-start
  display flex
  justify-content space-between
  align-items center

  h4
    max-width rem(727)

.account-settings-auth__loginContainer
  width 100%
  display flex
  flex-direction column
  justify-content center
  align-items center

.account-settings-auth__bottomContent
  display flex
  justify-content space-between
  flex-wrap wrap
  align-items center
  position absolute
  white-space nowrap
  left rem(40)
  right rem(40)
  bottom rem(24)
  gap rem(16)
  align-content center

  +media-down('xs')
    justify-content center

.account-settings-auth__bottomContent__links
  display flex
  flex-wrap wrap
  gap rem(16)

  +media-down('sm')
    color var(--color--neutral--light-5)
</style>

<script setup lang="ts">
const { t } = useClientI18n();
</script>
